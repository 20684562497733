import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { article } from '../styles/layout.css';
import { a, p, h2 } from '../styles/global.css';
import { atoms } from '../styles/sprinkles.css';

const ContactPage = () => {

  return (
    <Layout pageTitle="Contact">
      <article className={article}>
        <h2 className={h2}>Reach me around the world</h2>
        <p className={p}>
          Email my name at gmail.</p>
        <p className={p}>
          DM or mention <a href="https://twitter.com/benfoden" className={a}>@benfoden on twitter</a>.</p>
        <p className={p}>
          Read my <a href="https://www.linkedin.com/in/benfoden/" className={a}>linkedin profile</a>.</p>

      </article>
    </Layout>
  );
}
export default ContactPage;
